<template>
	<div class="resource-calendar-container">
		<v-layout class="py-2 d-flex justify-content-between align-items-center border-top">
			<v-flex class="my-auto">
				<v-btn color="blue darken-4 white--text" tile depressed class="mr-1" v-on:click="getToday()">
					Today
				</v-btn>
				<v-btn
					color="blue darken-4 white--text"
					tile
					depressed
					small
					width="30"
					min-width="30"
					height="26"
					class="mr-1"
					v-on:click="preview()"
				>
					<v-icon>mdi-menu-left</v-icon>
				</v-btn>
				<v-btn
					color="blue darken-4 white--text"
					tile
					depressed
					small
					width="30"
					min-width="30"
					height="26"
					class="mr-1"
					v-on:click="next()"
				>
					<v-icon>mdi-menu-right</v-icon>
				</v-btn>
				<div
					class="mx-5 font-level-3-bold d-inline-block primary--text fw-600"
					style="min-width: 150px"
				>
					{{ toolbarTitle }}
				</div>
			</v-flex>
			<v-flex class="d-flex justify-content-start align-items-center">
				<div class="mx-5 font-level-3-bold d-inline-block primary--text fw-600" style="">Filters</div>
				<v-flex md2 class="mr-1">
					<DatePicker outlined depressed hide-details placeholder="Select Date" custom-class="mt-0">
					</DatePicker>
				</v-flex>
				<v-flex md2 class="mr-1">
					<v-autocomplete
						:items="statusList"
						v-model="status"
						hide-details
						dense
						outlined
						placeholder="Select Status"
						color="blue darken-4"
						item-color="blue darken-4"
						item-text="text"
						item-value="status"
					>
						<template v-slot:selection="{ item, index }">
							<span v-if="index === 0">{{ item.text }}</span>
							<span v-if="index === 1" class="ml-2 grey--text text-caption">
								(+{{ status.length - 1 }} more)
							</span>
						</template>
						<template v-slot:item="{ item }">
							<v-list-item-action class="mr-0">
								<v-chip style="height: 16px !important; width: 16px; padding: 0" :color="item.color">
								</v-chip>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
									item.text
								}}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action class="align-self-center" v-if="status == item.status">
								<v-icon color="cyan">mdi-check</v-icon>
							</v-list-item-action>
						</template>
					</v-autocomplete>
				</v-flex>
				<v-flex md2 class="mr-1">
					<v-autocomplete
						:items="customerList"
						v-model="customer"
						hide-details
						outlined
						placeholder="Select Customer"
						color="blue darken-4"
						item-color="blue darken-4"
						item-text="display_name"
						item-value="id"
					>
						<template v-slot:selection="{ item }">
							<div class="text-truncate">{{ item.display_name }}</div>
						</template>
					</v-autocomplete>
				</v-flex>
				<v-flex md2 class="">
					<v-autocomplete
						:items="engineerList"
						v-model="engineer"
						hide-details
						dense
						outlined
						placeholder="Select Engineer"
						color="blue darken-4"
						item-color="blue darken-4"
						item-text="display_name"
						item-value="id"
					>
						<template v-slot:selection="{ item, index }">
							<span v-if="index === 0" class="text-truncate d-inline-block">{{ item.display_name }}</span>
							<span v-if="index === 1" class="ml-2 grey--text text-caption">
								(+{{ engineer.length - 1 }} more)
							</span>
						</template>
						<template v-slot:item="{ item }">
							<v-list-item-avatar
								class="mr-1"
								height="30"
								width="30"
								min-width="30"
								color="grey lighten-2 grey--text"
							>
								<span class="fw-500">
									{{ item.display_name.slice(0, 2).toUpperCase() }}
								</span>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
									item.display_name
								}}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action class="align-self-center" v-if="engineer == item.id">
								<v-icon color="cyan">mdi-check</v-icon>
							</v-list-item-action>
						</template>
					</v-autocomplete>
				</v-flex>
			</v-flex>
			<v-flex class="d-flex justify-content-end">
				<v-btn color="blue darken-4 white--text" tile depressed class="mr-1" v-on:click="dayView()">
					Day
				</v-btn>
				<v-btn color="blue darken-4 white--text" tile depressed class="mr-1" v-on:click="weekView()">
					Week
				</v-btn>
				<v-btn color="blue darken-4 white--text" tile depressed class="mr-1" v-on:click="monthView()">
					Month
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="resource-calender d-flex">
			<div class="calender-wrapper">
				<v-layout class="mb-2" v-if="false">
					<v-flex md2 class="mr-1">
						<DatePicker outlined depressed hide-details placeholder="Select Date" custom-class="mt-0">
						</DatePicker>
					</v-flex>
					<v-flex md2 class="mr-1">
						<v-autocomplete
							:items="statusList"
							v-model="status"
							hide-details
							clearable
							dense
							outlined
							placeholder="Select Status"
							color="blue darken-4"
							item-color="blue darken-4"
							item-text="text"
							item-value="status"
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0">{{ item.text }}</span>
								<span v-if="index === 1" class="ml-2 grey--text text-caption">
									(+{{ status.length - 1 }} more)
								</span>
							</template>
							<template v-slot:item="{ item }">
								<v-list-item-action class="mr-0">
									<v-chip style="height: 16px !important; width: 16px; padding: 0" :color="item.color">
									</v-chip>
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
										item.text
									}}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action class="align-self-center" v-if="status == item.status">
									<v-icon color="cyan">mdi-check</v-icon>
								</v-list-item-action>
							</template>
						</v-autocomplete>
					</v-flex>
					<v-flex md2 class="mr-1">
						<v-autocomplete
							:items="customerList"
							v-model="customer"
							hide-details
							clearable
							outlined
							placeholder="Select Customer"
							color="blue darken-4"
							item-color="blue darken-4"
							item-text="display_name"
							item-value="id"
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0">{{ item.display_name }}</span>
								<span v-if="index === 1" class="ml-2 grey--text text-caption">
									(+{{ customer.length - 1 }} more)
								</span>
							</template>
						</v-autocomplete>
					</v-flex>
					<v-flex md2 class="">
						<v-autocomplete
							:items="engineerList"
							v-model="engineer"
							hide-details
							clearable
							dense
							outlined
							placeholder="Select Engineer"
							color="blue darken-4"
							item-color="blue darken-4"
							item-text="display_name"
							item-value="id"
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0" class="text-truncate d-inline-block">{{ item.display_name }}</span>
								<span v-if="index === 1" class="ml-2 grey--text text-caption">
									(+{{ engineer.length - 1 }} more)
								</span>
							</template>
							<template v-slot:item="{ item }">
								<v-list-item-avatar
									class="mr-1"
									height="30"
									width="30"
									min-width="30"
									color="grey lighten-2 grey--text"
								>
									<span class="fw-500">
										{{ item.display_name.slice(0, 2).toUpperCase() }}
									</span>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
										item.display_name
									}}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action class="align-self-center" v-if="engineer == item.id">
									<v-icon color="cyan">mdi-check</v-icon>
								</v-list-item-action>
							</template>
						</v-autocomplete>
					</v-flex>
				</v-layout>
				<FullCalendar ref="fullCalendar" :options="calendarOptions" />
			</div>
			<div class="sidebar-event px-3 py-3">
				<div class="listing-contents">
					<v-flex class="mb-3 white">
						<v-autocomplete
							:items="engineerList"
							v-model="engineers"
							hide-details
							dense
							outlined
							prepend-inner-icon="mdi-magnify"
							placeholder="Select Engineer"
							color="blue darken-4"
							item-color="blue darken-4"
							item-text="display_name"
							item-value="id"
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0" class="text-truncate d-inline-block">{{ item.display_name }}</span>
								<span v-if="index === 1" class="ml-2 grey--text text-caption">
									(+{{ engineer.length - 1 }} more)
								</span>
							</template>
							<template v-slot:item="{ item }">
								<v-list-item-avatar
									class="mr-1"
									height="30"
									width="30"
									min-width="30"
									color="grey lighten-2 grey--text"
								>
									<span class="fw-500">
										{{ item.display_name.slice(0, 2).toUpperCase() }}
									</span>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
										item.display_name
									}}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action class="align-self-center" v-if="engineer == item.id">
									<v-icon color="cyan">mdi-check</v-icon>
								</v-list-item-action>
							</template>
						</v-autocomplete>
					</v-flex>
					<div
						class="list-items fc-event"
						v-for="item in visit_list"
						:key="item.id"
						:data-set="JSON.stringify(item)"
					>
						<div class="d-flex justify-content-between align-items-center">
							<v-chip x-small color="blue" :class="`white--text mr-2`" label>
								{{ item.id }}
							</v-chip>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<div class="visit-date d-flex align-items-center mr-2" v-on="on" v-bind="attrs">
										<v-icon size="18" color="green darken-4" class="mr-1">mdi-alarm</v-icon>
										<span class="green--text fw-500">{{ item.start }}</span>
									</div>
								</template>
								<span>Start date</span>
							</v-tooltip>
						</div>
						<div class="title">
							<span class="fw-700 text-truncate d-block">{{ item.title }}</span>
						</div>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<div class="visit-date d-flex align-items-center mr-2" v-on="on" v-bind="attrs">
									<v-icon size="18" color="red darken-4" class="mr-1">mdi-alarm</v-icon>
									<span class="red--text fw-500">{{ item.end }}</span>
								</div>
							</template>
							<span>End date</span>
						</v-tooltip>
					</div>
				</div>
			</div>
		</div>
		<DetailDrawer :drawer="open_detail" v-on:close="open_detail = false"></DetailDrawer>
	</div>
</template>
<script>
import DetailDrawer from "@/view/module/calendar/DetailDrawer";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import DatePicker from "@/view/components/DatePicker";
//import ObjectPath from "object-path";

export default {
	name: "Resource-calender",
	components: {
		FullCalendar,
		DetailDrawer,
		DatePicker,
	},
	data() {
		return {
			count: 0,
			open_detail: false,
			payload_data: null,
			status: "0",
			customer: "0",
			engineer: "0",
			toolbarTitle: null,
			calendarOptions: {
				schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
				plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
				timeZone: "UTC",
				initialView: "resourceTimelineDay",
				aspectRatio: 1.8,
				eventClick: this.handleEventClick,
				resourceLabelDidMount: this.mountResourceLabel,
				eventDidMount: this.mountEventdata,
				eventReceive: this.customEventReceive,
				resourceAreaWidth: "300px",
				droppable: true,
				drop: this.eventDrop,
				eventDragStop: this.customEventDragStop,
				eventDragStart: this.customEventDragStart,
				headerToolbar: {
					left: "prev,next",
					center: "title",
					right: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
				},
				editable: true,
				//resourceLabelDidMount: this.resourceLabel(info),
				resourceAreaHeaderContent: "Visits",
				resources: null,
				events: null,
			},
			resource_name: [
				{
					id: "a",
					title: "Auditorium A",
					designation: "Develpoer",
					total_task: 5,
				},
				{
					id: "b",
					title: "Auditorium B",
					designation: "B Develpoer",
					total_task: 13,
				},
				{
					id: "c",
					title: "Auditorium C",
					designation: "C Develpoer",
					total_task: 3,
				},
				{
					id: "d",
					title: "Auditorium Auditorium Auditorium D",
					designation: "D Develpoer",
					total_task: 15,
					children: [
						{
							id: "d1",
							title: "Room D1",
							designation: "Team D1 Develpoer",
							total_task: 7,
							child: true,
						},
						{
							id: "d2",
							title: "Room D2",
							designation: "Team D2 Develpoer",
							total_task: 8,
							child: true,
						},
					],
				},
				{
					id: "e",
					title: "Auditorium E",
					designation: "E Develpoer",
					total_task: 12,
				},
				{
					id: "f",
					title: "Auditorium F",
					designation: "F Develpoer",
					total_task: 5,
				},
			],
			event_name: [
				{
					resourceId: "a",
					title: "event 1",
					visitId: "Visit123",
					start: "2022-07-12T12:00:00+00:00",
					end: "2022-07-12T12:00:00+00:00",
					bg_color: "cyan",
					classNames: "custom-event cyan",
				},
				{
					resourceId: "c",
					title: "event 3",
					visitId: "Visit123",
					start: "2022-07-11T12:00:00+00:00",
					end: "2022-07-11T16:00:00+00:00",
					bg_color: "orange",
					classNames: "custom-event orange",
				},
				{
					resourceId: "d",
					title: "event d",
					visitId: "Visit123",
					start: "2022-07-11T08:00:00+00:00",
					end: "2022-07-11T16:00:00+00:00",
					bg_color: "green",
					classNames: "custom-event green",
				},
				{
					resourceId: "f",
					title: "event f",
					visitId: "Visit123",
					start: "2022-07-11T08:00:00+00:00",
					end: "2022-07-11T16:00:00+00:00",
					bg_color: "blue",
					classNames: "custom-event blue",
				},
				{
					resourceId: "f",
					title: "event 4",
					visitId: "Visit123",
					start: "2022-07-11T16:30:00+00:00",
					end: "2022-07-11T22:30:00+00:00",
					bg_color: "yellow",
					classNames: "custom-event yellow",
				},
				{
					resourceId: "b",
					title: "event 5",
					visitId: "Visit123",
					start: "2022-07-11T10:00:00+00:00",
					end: "2022-07-11T15:00:00+00:00",
					bg_color: "green",
					classNames: "custom-event green",
				},
				{
					resourceId: "e",
					title: "event 2",
					visitId: "Visit123",
					start: "2022-07-12T05:00:00+00:00",
					end: "2022-07-12T15:00:00+00:00",
					bg_color: "orange",
					classNames: "custom-event orange",
				},
			],
			visit_list: [
				{
					id: "visit1",
					title: "Painitng",
					duration: "02:00",
					status_color: "orange",
					classNames: "orange",
					start: "03/03/2022 04:30 PM",
					end: "03/03/2022 04:30 PM",
				},
				{
					id: "visit12",
					title: "ADHOC TESTING",
					status_color: "green",
					classNames: "green",
					start: "03/03/2022 04:30 PM",
					end: "03/03/2022 04:30 PM",
				},
				{
					id: "visit3",
					title: "Inspection",
					status_color: "pink",
					classNames: "pink",
					start: "03/03/2022 04:30 PM",
					end: "03/03/2022 04:30 PM",
				},
				{
					id: "visit4",
					title: "Delivert",
					status_color: "blue",
					classNames: "blue",
					start: "03/03/2022 04:30 PM",
					end: "03/03/2022 04:30 PM",
				},
			],
			statusList: [
				{
					type: 12,
					text: "All Visits",
					value: "all",
					description: "All Visits",
					activity_text: "All Visits",
					color: "cyan",
					textcolor: "white",
					headercolor: "primary",
					order: 1,
					status: 0,
					top_visible: 1,
				},
				{
					type: 12,
					text: "Open",
					value: "open",
					description: "Open Visits",
					activity_text: "Open",
					color: "blue darken-1",
					textcolor: "white",
					headercolor: "primary",
					order: 2,
					status: 1,
					top_visible: 1,
				},
				{
					type: 12,
					text: "In-Progress",
					value: "in-progress",
					description: "In-Progress Visits",
					activity_text: "In-Progress",
					color: "yellow accent-4",
					textcolor: "blue-grey darken-4",
					headercolor: "primary",
					order: 3,
					status: 2,
					top_visible: 1,
				},
				{
					type: 12,
					text: "Hold",
					value: "hold",
					description: "Hold Visits",
					activity_text: "Hold",
					color: "orange darken-4",
					textcolor: "white",
					headercolor: "primary",
					order: 4,
					status: 3,
					top_visible: 1,
				},
				{
					type: 12,
					text: "Completed",
					value: "completed",
					description: "Completed Visits",
					activity_text: "Completed",
					color: "green darken-1",
					textcolor: "white",
					headercolor: "primary",
					order: 5,
					status: 4,
					top_visible: 1,
				},
				{
					type: 12,
					text: "Cancelled",
					value: "cancelled",
					description: "Cancelled Visits",
					activity_text: "Cancelled",
					color: "red lighten-1",
					textcolor: "white",
					headercolor: "primary",
					order: 6,
					status: 5,
					top_visible: 1,
				},
			],
			customerList: [
				{
					id: 1,
					display_name: "Business Thrust Techsoft Pvt Ltd.",
				},
				{
					id: 2,
					display_name: "jeny",
				},
				{
					id: 3,
					display_name: "Ethan Alex",
				},
			],
			engineerList: [
				{
					id: 1,
					display_name: "John Doe",
				},
				{
					id: 2,
					display_name: "john",
				},
				{
					id: 3,
					display_name: "Mandarin Mandarin",
				},
				{
					id: 4,
					display_name: "Ang peng Siong",
				},
			],
		};
	},
	methods: {
		next() {
			let calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.next();
			this.toolbarTitle = calendarApi.view.title;
		},
		preview() {
			let calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.prev();
			this.toolbarTitle = calendarApi.view.title;
		},
		getToday() {
			let calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.today();
			this.toolbarTitle = calendarApi.view.title;
		},
		dayView() {
			let calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.changeView("resourceTimelineDay");
			this.toolbarTitle = calendarApi.view.title;
		},
		weekView() {
			let calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.changeView("resourceTimelineWeek");
			this.toolbarTitle = calendarApi.view.title;
		},
		monthView() {
			let calendarApi = this.$refs.fullCalendar.getApi();
			calendarApi.changeView("resourceTimelineMonth");
			this.toolbarTitle = calendarApi.view.title;
		},
		mountEventdata(info) {
			if (info.event._def.extendedProps.visitId != undefined) {
				console.log({ mountEventdata: info.event._def.extendedProps.visitId });
				let infoElement = document.createElement("div");
				infoElement.innerHTML = info.event._def.extendedProps.visitId;
				infoElement.classList.add("fc-event-id");
				infoElement.classList.add("darken-2");
				infoElement.classList.add(info.event._def.extendedProps.bg_color);
				let elementNode = info.el.querySelector(".fc-event-title-container");
				elementNode.prepend(infoElement);
			}
		},
		mountResourceLabel(info) {
			let profie_name = document.createElement("div");
			let status = document.createElement("span");
			let profie_image = document.createElement("img");
			let total_count = document.createElement("div");

			profie_name.innerHTML = info.resource._resource.extendedProps.designation;
			status.setAttribute("class", "resource_status green");
			profie_image.setAttribute("src", "https://cdn.vuetifyjs.com/images/john.jpg");
			total_count.innerHTML = info.resource._resource.extendedProps.total_task;

			total_count.setAttribute("class", "resource_user_total");
			if (info.resource._resource.extendedProps.child) {
				profie_image.setAttribute("class", "resource_user_image ml-5");
				profie_name.setAttribute("class", "resource_user_designation pl-72");
			} else {
				profie_image.setAttribute("class", "resource_user_image mr-3");
				profie_name.setAttribute("class", "resource_user_designation pl-52");
			}
			let elementNode = info.el.querySelector(".fc-datagrid-cell-cushion");
			let StatusElementNode = info.el.querySelector(".fc-datagrid-cell-main");

			elementNode.prepend(profie_image);
			elementNode.append(profie_name);
			elementNode.append(total_count);
			StatusElementNode.append(status);
		},
		dragableEvent() {
			let containerEl = document.querySelector(".listing-contents");
			new Draggable(containerEl, {
				itemSelector: ".fc-event",
				eventData: this.getPayload,
			});
		},
		getPayload(eventEl) {
			console.log({ payload: eventEl });
			this.payload_data = JSON.parse(eventEl.getAttribute("data-set"));
			//console.log(this.payload_data);
			return this.payload_data;
		},
		eventDrop(data) {
			//console.log({drop:data});
			const payload = this.payload_data;
			const eventData = {
				resourceId: data.resource._resource.id,
				title: payload.title,
				start: data.dateStr,
				visitId: payload.id,
				end: data.dateStr,
				bg_color: payload.status_color,
				classNames: `custom-event ${payload.status_color}`,
			};
			//console.log({eventData:eventData});
			this.event_name.push(eventData);
		},
		customEventReceive(info) {
			console.log(info);
		},
		handleEventClick() {
			this.open_detail = true;
			//console.log(info.event.title);
		},
	},
	mounted() {
		let calendarApi = this.$refs.fullCalendar.getApi();
		this.toolbarTitle = calendarApi.view.title;
		this.calendarOptions.resources = this.resource_name;
		this.calendarOptions.events = this.event_name;
		this.dragableEvent();
	},
};
</script>
<style scoped></style>
