<template>
	<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
		<div class="drawer-wrapper">
			<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
				<div class="font-level-3-bold">Task Details</div>
				<v-spacer></v-spacer>
				<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
				<v-btn tile depressed color="white--text" class="blue darken-4">Edit</v-btn>
			</v-flex>
			<div class="drawer-content pt-5 px-5">
				<div class="col-12">
					<p class="font-level-3-bold">
						<span class="custom-bottom-border">Visit Title</span>
					</p>
					<div>
						<table class="width-100">
							<tr>
								<td width="200" class="fw-600 py-1">Visit number</td>
								<td class="py-1">VISIT-152</td>
							</tr>
							<tr>
								<td width="200" class="fw-600 py-1">Contact Person</td>
								<td class="py-1">John</td>
							</tr>
							<tr>
								<td width="200" class="fw-600 py-1">Email</td>
								<td class="">
									<span>john@bthrust.com</span>
								</td>
							</tr>
							<tr>
								<td width="200" class="fw-600 py-1">Phone</td>
								<td class="">
									<span>+65234234</span>
								</td>
							</tr>
							<tr>
								<td width="200" class="fw-600 mt-1">Appointment</td>
								<td class="fw-600 py-1">
									<a href="javascript:void(0)" class="text-uppercase max-content-width"
										>07/07/2022 12:00 AM - 11:59 PM</a
									>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="col-12">
					<v-divider></v-divider>
					<div class="layout my-4">
						<div class="flex mr-2 custom-border-right md6">
							<table width="100%">
								<tr>
									<td class="fw-600">Service Location</td>
								</tr>
								<tr>
									<td class="fw-500">
										<a
											target="_blank"
											href="https://maps.google.com/?q=874D Tampines Street 85, Tampines Street 85 874D, Tampines 524874"
											>874D Tampines Street 85, Tampines Street 85 874D, Tampines 524874</a
										>
									</td>
								</tr>
								<tr>
									<th class="fw-600 pt-5 pb-1">Contact details</th>
								</tr>
								<tr>
									<td class="fw-500">aiden</td>
								</tr>
								<tr>
									<td class="fw-500">+65-76575677</td>
								</tr>
								<tr>
									<td class="fw-500">aideneric77@gmail.com</td>
								</tr>
							</table>
						</div>
						<div class="flex ml-2 md6">
							<table width="100%">
								<tr>
									<td class="fw-600">Billing Address</td>
								</tr>
								<tr>
									<td class="py-0 fw-500">
										<a
											target="_blank"
											href="https://maps.google.com/?q=767 Bedok Reservoir View, Bedok Reservoir View 767, Bedok 470767"
											>767 Bedok Reservoir View, Bedok Reservoir View 767, Bedok 470767</a
										>
									</td>
								</tr>
								<tr>
									<td class="fw-600 py-5 pb-1">Contact details</td>
								</tr>
								<tr>
									<td class="fw-500">jimkim</td>
								</tr>
								<tr>
									<td class="fw-500">+65-67587648</td>
								</tr>
								<tr>
									<td class="fw-500">kim@bt.com</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</v-navigation-drawer>
</template>
<script>
export default {
	name: "eventDetails",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		dataEvent: {
			type: Object,
		},
	},
	data() {
		return {};
	},
};
</script>
